import React from "react";
import { connect } from 'react-redux';
import ResponsiveMedia from 'utils/responsive_media';
import Select from 'components/select/select';
import { Container, Box } from 'components/style/layoutStyle';
import { TextNormal, AccentText } from "components/style/textStyle";

const TopSection = ({category, actCat, notiData, onChangeEvent}) => {
    const { isMobile } = ResponsiveMedia();
    return (
        <Container>
            <Box
                padding={isMobile === 'true'? '0px 20px 20px' : '60px 20px 24px'}
                justifycontent={'space-between'}>
                <TextNormal
                    padding={'0'}
                    type={'16'}>
                    총&nbsp;<AccentText>{notiData && notiData.length}</AccentText>개
                </TextNormal>
                
                <Select 
                    width={isMobile === 'true'? '92px' : '156px'}
                    initVal={actCat}
                    onChangeEvent={value => onChangeEvent(value)}
                    data={category}
                />
            </Box>
        </Container>
    )
}

export default connect(state => ({
    notiData: state.support.notice_data,
}), null)(TopSection);