import React, { useRef, useState } from "react";
import useOverlayDetect from "hooks/useOverlayDetect";

import Image from "../image/image";
import { SelectBox, SelectLabelBox, LabelText, OptionList, OptionItem, ArrowImageWrapper } from "./style";
import { IC_SELECT_ARROW, IC_SELECT_ARROW_HOVER } from "../../constants/media/icon";
import { useTranslation } from "react-i18next";

const CustomSelect = ({ data, width, height, initVal = 0, onChangeEvent }) => {
    const { t } = useTranslation();
    let $SelectBox = useRef(null);

    const [active, setActive] = useState(false);
    const [hover, setHover] = useState(false);
    const [select, setSelect] = useState(initVal);

    const handleSelect = (index) => {
        setActive(false);
        setSelect(index);
        onChangeEvent && onChangeEvent(index);
    };

    const handleHover = (event) => {
        setHover(event.type === "mouseenter");
    };

    useOverlayDetect($SelectBox, () => {
        setActive(false);
    });

    return (
        <SelectBox width={width} height={height} ref={$SelectBox} onMouseEnter={handleHover} onMouseLeave={handleHover}>
            <SelectLabelBox onClick={() => setActive(!active)}>
                <LabelText>{t(data[select])}</LabelText>
                <ArrowImageWrapper open={active}>{hover ? <Image filename={IC_SELECT_ARROW_HOVER} alt={"select arrow icon hover"} /> : <Image filename={IC_SELECT_ARROW} alt={"select arrow icon"} />}</ArrowImageWrapper>
            </SelectLabelBox>
            <OptionList active={active}>
                {data.map((item, index) => {
                    return (
                        <OptionItem selected={index === select} key={index} onClick={() => handleSelect(index)}>
                            {t(item)}
                        </OptionItem>
                    );
                })}
            </OptionList>
        </SelectBox>
    );
};

export default CustomSelect;
