import styled from "styled-components";

export const SelectBox = styled.div`
    width: ${(props) => props.width || "67px"};
    height: ${(props) => props.height || "32px"};
    position: relative;

    > ul {
        top: ${(props) => props.height || "32px"};
    }
`;

export const SelectLabelBox = styled.div`
    width: 100%;
    height: 100%;

    border: solid 1px #ddd;
    background-color: #fff;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 12px;
    background-color: #ffffff;

    &: hover {
        background-color: #f8f8f8;
    }
`;

export const LabelText = styled.div`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.42px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #1e1e1e;
    overflow: hidden;
`;

export const OptionList = styled.ul`
    z-index: 9999;
    position: absolute;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    list-style-type: none;
    overflow: hidden;
    transition: all 0.2s ease;
    margin: 0;

    ${(props) =>
        props.active
            ? `
        max-height: 500px;
        padding: 4px 0;
    `
            : `
        max-height: 0;
        padding: 0;
    `}
`;

export const OptionItem = styled.li`
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0 6px 0 12px;
    background-color: #ffffff;
    cursor: pointer;
    margin: 0;

    ${(props) => props.selected && `font-weight:500;`};
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.42px;
    text-align: left;
    color: #1e1e1e;

    &: hover {
        background-color: #f8f8f8;
    }
`;

export const ArrowImageWrapper = styled.div`
    width: 24px;
    height: 24px;
    transition: all 0.2s ease-in-out;
    ${(props) =>
        props.open
            ? `
        transform: rotate(180deg);
    `
            : null};
`;
