import React, { useEffect } from "react";
import { connect } from "react-redux";
import { SupportActions } from "redux/actions";
import OrganizeSupportData from "../organizeSupportData";
import NoticeTable from "../table/noticeTable";
import TopSection from "./topSection";

const Notice = ({ data, category, actCat, keyword = null }) => {
    const { setNoticeData } = OrganizeSupportData();

    const handleNoticeCategory = (value) => {
        SupportActions.setNoticeCategory(value);
    };

    useEffect(() => {
        setNoticeData(data, keyword, actCat);
    }, [actCat]);

    useEffect(() => {
        SupportActions.setKeyword(keyword);
    }, []);

    return (
        <>
            <TopSection category={category} actCat={actCat} onChangeEvent={handleNoticeCategory} />
            <NoticeTable category={actCat} />
        </>
    );
};

export default connect(
    (state) => ({
        actCat: state.support.notice_category
    }),
    null
)(Notice);
